// Main javascript entry point
// Should handle bootstrapping/starting application

'use strict';

$(function() {
  //Checks if .preview-mode class exists
  var container = '.preview-mode';

  if ($(container).length) {
    $('input, textarea, select', container).each(function() {
      //Disables all input forms inside .preview-mode
      $(this).prop('disabled', true);
    });
  }

  // Mobile menu functionality
  $('#mobile-icon').on('click', function () {
    $(this).toggleClass('opened');
    $('#js-main-nav').slideToggle();
  });

  $('.multiselect').chosen({
    disable_search_threshold: 4,
    no_results_text: 'Ni zadetkov za: '
  });  

  // Reset all collapsing checkboxes on page load
  $('.collapser').each(function () {
    $(this).prop('checked', false);
  });
});